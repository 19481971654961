<template>
  <div class="submitEnding-index">
    <textarea id="input" :value="copyNum" ref='copyInput'></textarea>
    <div class="submitEnding-wrap">
      <div class="submit_img">
        <img src="@/assets/submitEnding/post_success_result.png" alt="">
      </div>
      <p class="submit_p">您参与的认购已提交成功</p>
      <p class="submit_order" @click="toMyBuy">点击前往认购参与记录</p>
      <div class="order_list_wrap" v-if="type!=='zuhe'">
        
        <!-- <p v-for="(item,index) in this.$route.query.res" :key="index"> -->
        <p >
          <span>{{fwOrgName || ''}}</span>
          <span>参与编码：<i class="order_no">{{code}}</i></span>
            <span class="copy" @click="copy(code)">复制</span>
        </p>
      </div>
      <a-button @click="backIndex" type="primary"> 返回首页</a-button>
      <a-button @click="backActivity">返回活动专区</a-button>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      copyNum:null,
      code:this.$route.query.code,
      fwOrgName:this.$route.query.fwOrgName,
      list:[
        {
          name:'空调青岛',
          code:'CPO1900000364'
        }
      ],
      type:''
    }
  },
  created() {
    // this.list = this.$route.query.resus.split('$$').map(it=>{
    //   return it.split(',')
    // })
    this.type = this.$route.query.type
  },
  methods:{
    copy(data){
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.info('复制成功');
      oInput.remove()
    },
    toMyBuy(){
      this.$router.push({
        path:'/marketActivityResult',
      })
    },
    backIndex(){
      this.$router.push({
        path:'index',
      })
    },
    backActivity(){
      this.$router.push({
        path:'activity'
      })
    },
  }
}
</script>
<style lang="less" scoped>
.submitEnding-index{
  font-family: PingFangSC-Medium;
  position: relative;
  background:#f7f7f7;
  overflow: hidden;
  #input{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -10;
  }
  .submitEnding-wrap{
    width:1188px;
    height:556px;
    background:#fff;
    margin:16px auto 40px;
    text-align: center;
    overflow: hidden;
    .submit_img{
      width: 130px;
      height:130px;
      margin:70px auto 24px;
    }
    .submit_p{
      font-size: 20px;
      color: #262626;
      letter-spacing: 0;
      line-height: 26px;
      font-weight: 500;
    }
    .submit_order{
      font-size: 16px;
      color: #00AAA6;
      letter-spacing: 0;
      line-height: 24px;
      margin:8px 0 16px;
      cursor: pointer;
    }
    .order_list_wrap{
      max-width: 420px!important;
      margin:0 auto 40px;
      background: #F7F7F7;
      padding:0 24px 12px 24px;
      box-sizing: border-box;
      overflow: hidden;
      & p{
        font-size: 12px;
        color: #777777;
        line-height: 20px;
        margin-top:12px;
        display: flex;
        justify-content: space-between;
        .order_no{
          color:#262626;
        }
        .copy{
          cursor: pointer;
        }
        .copy:hover{
          color:#00AAA6;
        }
      }
    }
    /deep/.ant-btn{
      width: 118px;
      height:40px;
      border-radius: 0;
      color: #00ABAF;
      border: 1px solid #00ABAF;
    }
    /deep/.ant-btn-primary{
      background: #00ABAF;
      color:#fff;
      margin-right: 16px;
    }
  }
  
}
</style>

